<template>
  <div id="cos-add">
    <el-form label-width="80px">
      <el-form-item label="注意">
        一次只能上传一个文件
      </el-form-item>
      <el-form-item label="文件名称">
        <el-input v-model="fileName"></el-input>
      </el-form-item>
      <el-form-item label="选文件">
        <aidaUploader
          v-model="attach"
          :readonly="false"
          :bizType="bizType"
          :isSingleFile="isSingleFile"
          :maxSize="1"
          v-on:update-attach="updateAttach"
        >
        </aidaUploader>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onAddCos">立即创建</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>

      <el-form-item label="测试区">
        一次上传多个文件
      </el-form-item>
      <el-form-item label="选文件">
        <aidaUploader
          v-model="attachMulti"
          :readonly="false"
          :bizType="bizType"
          :isSingleFile="false"
          :maxSize="1000"
          v-on:update-attach="updateAttachMulti"
        >
        </aidaUploader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onAddCosMulti">保存多个文件</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  // addCos,
  // COS_STS_URL,
  // getBucketRegion,
  // getCosHolder
  cosApi,
} from "@/api/cosApi.js";
import COS from "cos-js-sdk-v5";
import { alertMessage } from "@/util/alertMessageUtil.js";
import aidaUploader from "@/component/aidaUploader.vue";
import msgLogger from "@/util/msgLogger";

export default {
  components: {
    aidaUploader,
  },
  data() {
    return {
      bizType: "base",
      isSingleFile: true,
      fileName: "",
      attach: [],

      attachMulti: [],
    };
  },
  mounted() {},

  methods: {
    updateAttach(retList) {
      console.log("update attach", retList);
      if (retList && retList.length > 0) {
        this.fileName = retList[0].fileName;
      } else {
        this.fileName = "";
      }
    },
    updateAttachMulti(retList) {
      console.log("## update attach multi", retList);
    },
    // 保存cos
    onAddCos() {
      if (this.attach && this.attach.length > 1) {
        msgLogger.warn("一次只能上传一个文件，保存一个文件");
        return;
      }
      this.attach[0].fileName = this.fileName;
      cosApi
        .batchAddCos(this.attach)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            msgLogger.done("cos 保存成功");
          }
        })
        .catch((err) => {});
    },
    onAddCosMulti() {
      cosApi
        .batchAddCos(this.attachMulti)
        .then((ret) => {
          let { code, data } = ret;
          if (code == 0 && data) {
            msgLogger.done("cos 保存成功");
          }
        })
        .catch((err) => {});
    },
    onCancel() {
      this.$router.push({
        path: "/cos",
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
#cos-add {
  margin: 20px;
  width: 60%;
  min-width: 600px;
}

.showFileCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.showFileCover :hover {
  cursor: pointer;
}

.cosFileName {
  border-bottom: 1px solid blue;
}

.cosFileName :hover {
  cursor: pointer;
}

.showFile {
  width: 320px;
  height: 320px;
}
</style>
